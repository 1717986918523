<template>
  <div>
    <div class="flex flex-col mt-8 mb-5">
      <h2 class="text-lg mb-5">Payment Links</h2>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-5">
        <div
          v-for="(payment, i) in paymentOptions"
          :key="i"
          class="col-span-1 flex flex-col text-11"
        >
          <h3 class="text-12 font-bold">
            {{ payment.title }}
          </h3>
          <div class="inline-flex items-center mt-1">
            <p class="text-gray-500 text-10">{{ payment.body }}</p>
            <div
              v-for="(button, index) in payment.buttons"
              :key="index"
              class="-mt-5"
            >
              <button
                :class="[
                  button.className,
                  i === 0 && 'px-6',
                  i === 1 && 'px-2 ml-1'
                ]"
                class="
                  inline-flex
                  items-center
                  text-10 text-white
                  w-full
                  py-2
                  ml-3
                  font-semibold
                  justify-center
                  rounded
                  focus:outline-none
                "
                @click="button.onClick"
              >
                <ion-icon
                  :name="button.icon.name"
                  :class="button.icon.class"
                  class="mr-2 text-sm"
                />
                {{ button.title }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <qrcode
      v-if="paymentLink"
      ref="qrcode"
      :value="paymentLink"
      :quality="1"
      class="hidden"
    />
  </div>
</template>
<script>
import VueQrcode from 'vue-qrcode';
export default {
  components: {
    qrcode: VueQrcode
  },
  computed: {
    paymentOptions() {
      return [
        {
          title: 'Credit Payment Link',
          body: 'Share link with customers to pay you.',
          icon: 'copy-outline',
          buttons: [
            {
              className: 'bg-blue-500 text-white',
              title: 'Copy link',
              icon: {
                name: 'copy-outline',
                class: 'text-white'
              },
              onClick: () => this.copyText(this.paymentLink)
            }
          ]
        },
        {
          title: 'Physical Store Payment QR',
          body: 'Directly share qrcode with customers for payment.',
          icon: 'qr-code-outline',
          buttons: [
            {
              className: 'bg-corporate-100',
              title: 'Get QR Code',
              icon: {
                name: 'qr-code-outline',
                class: 'text-white'
              },
              onClick: () => this.downloadImage(this.$refs.qrcode?.dataUrl)
            }
          ]
        }
      ];
    },
    paymentLink() {
      return this.$parent.merchantConfig?.payment_link;
    }
  },
  methods: {
    downloadImage(url) {
      const a = document.createElement('a');
      a.href = url;
      a.download = `${this.$parent?.$props?.profile?.business_name}-QRCode.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
};
</script>
